class ZIDXAccountPaymentCancel implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPaymentCancelContainer";
    }
    getMatchURL(){
        return "/account/payment/cancel";
    }
    render(){
        // console.log("payment cancel");
    }
}